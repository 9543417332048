<template>
    <div>
        <houses-component/>
        <footer-component/>
    </div>
</template>

<script>
    import HousesComponent from "@/components/houses/HousesComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Houses",
        title: "Casas de Recuperación | Turismo BC",
        components: {
            HousesComponent, FooterComponent
        },
    }
</script>

<style scoped>

</style>
<template>
    <v-container class="fill-height text-left pa-0 ma-0" fluid>
        <v-row justify="start">
            <v-col cols="12">
                <v-card class="fill-height container--fluid pa-0 ma-0" flat tile width="100%">
                    <v-slide-x-transition appear>
                        <v-img class="grey lighten-2"
                               height="500" src="@/assets/images/rest-house.jpg" width="100%">
                            <div class="fill-height bottom-gradient" style="width: 100%">
                                <v-container class="fill-height align-end pb-15" fluid>
                                    <v-row align="end" justify="start">
                                        <v-col class="pl-5 pl-md-15" cols="12">
                                            <v-sheet :width="$vuetify.breakpoint.xs ? '100%' : 600"
                                                     class="transition-swing panel-filter-color"
                                                     elevation="10" height="250"
                                                     light outlined
                                                     rounded="lg">
                                                <v-container class="pa-5 ma-5" fluid>
                                                    <v-row align="left" justify="start">
                                                        <v-col cols="12">
                                                            <div class="grey--text text--darken-3 mx-1">
                                                                <span class="text-md-h4">
                                                                    Lo mejor de Baja California
                                                                </span>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <div class="grey--text text--darken-4 ml-1 mr-5">
                                                                <span class="text-md-h5">
                                                                    Siéntase cómodo en cualquiera de nuestras
                                                                    Casas para un merecido descanso y pronta recuperación.
                                                                </span>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-img>
                    </v-slide-x-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <div class="boxdivider"></div>
        </v-row>
        <v-row justify="center">
            <div class="d-flex fill-height fluid pt-8 pb-8 px-5 px-md-10" style="width: 100%">
                <v-container class="fill-height" fluid>
                    <v-row align="center" justify="start">
                        <v-col
                            v-for="(item, index) in houses"
                            :key="index"
                            class="d-flex child-flex"
                            cols="12" sm="6" md="4">
                            <div class="fill-height">
                                <!-- v-if="item.pictures[0] != null" -->
                                <v-card
                                        class="rounded-lg zoom-img"
                                        elevation="10"
                                        height="auto"
                                        hover
                                        outlined
                                        width="100%"
                                        v-on:click="showProfile(item.id)">
                                    <v-img
                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                        :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                        class="white--text align-end justify-start zoom-image"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                                        height="350"
                                        position="center">
                                        <template v-slot:placeholder>
                                            <v-row
                                                align="center"
                                                class="fill-height ma-0"
                                                justify="center">
                                                <v-progress-circular
                                                    color="grey lighten-5"
                                                    indeterminate>
                                                </v-progress-circular>
                                            </v-row>
                                        </template>

                                        <div class="white--text mx-2">
                                            <p class="text-left font-weight-light text-h5">
                                                {{ item.name }}
                                            </p>
                                        </div>
                                    </v-img>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-row>
        <v-row justify="center" class="ma-10" v-if="houses.length === 0">
            <div>
                No hay Casas de Recuperación en el catálogos...
            </div>
        </v-row>
    </v-container>
</template>

<script>
    import housesService from '@/providers/HousesService';
    
    export default {
        name: "HousesComponent",
        data: () => ({
            selectedId: 0,
            houses: [],
            transparent: "rgba(255, 255, 255, 0)",
        }),
        methods: {
            getHouses() {
                housesService.getViewList().then(record => {
                    this.houses = record.value;
                    console.log("Casas: ", this.houses);
                });
            },
            showProfile(idHouse) {
                this.selectedId = idHouse;
                this.$router.push({
                    name: "HouseProfile",
                    params: {
                        id: idHouse,
                    }
                });
            }
        },
        mounted() {
            this.houses = [];
            this.getHouses();
        },
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
    .backcolor {
        background-color: transparent;
    }
    .panel-filter-color {
        background: rgba(255, 255, 255, 0.767) 100%;
    }
    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }
    .zoom-img {
        overflow: hidden;
        border-radius: 10px;
    }
    .zoom-img > img {
        object-fit: cover;
        transition: all .3s ease-in-out;
    }
    .zoom-img:hover > img {
        transform: scale(1.05);
    }
    .zoom-image {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        /* VERZÖGERUNG */
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        overflow: hidden;
    }
    .zoom-image:hover {
        -webkit-transform: scale(1.07);
        -moz-transform: scale(1.07);
        -ms-transform: scale(1.07);
        -o-transform: scale(1.07);
        transform: scale(1.07);
    }
</style>